
import fert from "../Img/OwnerImage.jpeg";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Service = [
  {
    id: 1,
  
    ServiceName: "Receptonist",
    Requirements:"1-2 years", 
    ServicePara1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  ",   
ServiceImg:fert,

    },

  {
    id: 2,
    
    ServiceName: "Sales Executive",
    Requirements:"1-2 years", 
    ServicePara1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  ",
      ServiceImg: fert,

    
  },
 
  {
    id: 3,
  
    ServiceName: "Personal Asistant",
    Requirements:"1-2 years", 
    ServicePara1:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
   
    ServiceImg: fert,
     },
  {
    id: 4,
    
    ServiceName: "Marketing Manager",
    Requirements:"1-2 years", 
    ServicePara1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
    ServiceImg: fert,

    ServicePara3: "Our Expert Doctor",
  },
 
];

export default Service;
