import { React, useLayoutEffect } from "react";
import Footer from "../components/Footer";
import maple from "../Img/banner.jpg";
const PRE_LEASE_ASSETS = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="propertyDetials">
        {/* <h1>Exclusive Project</h1>
        <h5>Find your dream homes with me.</h5> */}
      </div>
      <section className="creerBenefits">
        <div className="container">
          <div className="titleSection">
            <h2>New Project</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="careerBenefitImage">
                <img
                  src={maple}
                  alt="Training and Devlopment"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <div className="careerText">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci accusamus eius ducimus, atque praesentium eos
                  repellendus explicabo, iusto reiciendis nihil in eum ipsa
                  reprehenderit? Officia fugiat laboriosam beatae obcaecati
                  quibusdam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci accusamus eius ducimus, atque praesentium eos
                  repellendus explicabo, iusto reiciendis nihil in eum ipsa
                  reprehenderit? Officia fugiat laboriosam beatae obcaecati
                  quibusdam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PRE_LEASE_ASSETS;
