import fert from "../Img/Ramdev-veeral-heights/Ramdev_Viral_Night_View_V1 (1) (1).jpg";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const property = [
//   {
//     id: 1,
//     Category:"Upcoming",
//     ServiceName: "Maple Heights",
//     Requirements:"1-2 years",
//     ServicePara1:
//     "Enclave Healthcare offers comprehensive consultation and evaluation for infertility.We have a dedicated team which understands your anxieties and doubts and is ever willing to patiently discuss the detailed customised treatment plan for you. ",   
// ServiceImg:fert,

//     },

  {
    id: 1,
    Category:"exclusive",
    ServiceName: "Ramdev Veeral Heights",
    Requirements:"1-2 years", 
    ServicePara1:
    "We have a dedicated team of experts who will evaluate your needs and offer the latest Assisted reproductive techniques with state of the Art equipment. ",
      ServiceImg: fert,

    
  },
 
//   {
//     id: 3,
  
//     ServiceName: "Pediatric",
//     ServicePara1:
//       "Enclave healthcare provides Comprehensive Pediatric consultations and immunisation services with growth and nutritional evaluation of Children in the  0-18 years of age by Dr Savita Naik a senior Pediatrician with 25 years of experience. ",
   
//     ServiceImg: fert,
//      },
//   {
//     id: 4,
    
//     ServiceName: "Maternity Services",
//     ServicePara1:
//     "Enclave Healthcare provides antenatal services from conception till child birth.Even though pregnancy and child birth is a normal physiological process, expecting mothers are very apprehensive about their babies and their own well being during pregnancy! ",
//     ServiceImg: fert,

//     ServicePara3: "Our Expert Doctor",
//   },
 
];

export default property;
