import { React, useLayoutEffect } from "react";
import "../css/ResidencialandCommercial.css";
import Commercial from "../Img/commercial1.jpg";
import Footer from "../components/Footer";
import maple from "../Img/banner.jpg";
const ResidencialandCommercial = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="propertyDetials">
        {/* <h1>Exclusive Project</h1>
        <h5>Find your dream homes with me.</h5> */}
      </div>
      {/* https://www.realtorpal.hocud.com/wp-content/uploads/modern-house-facade.jpg */}
      <section className="creerBenefits">
        <div className="container">
          <div className="titleSection">
            <h2>Redevelopment</h2>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="careerBenefitImage">
                <img
                  src={maple}
                  alt="Training and Devlopment"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <div className="careerText">
                <h3>
                  <span>Residential</span>
                </h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci accusamus eius ducimus, atque praesentium eos
                  repellendus explicabo, iusto reiciendis nihil in eum ipsa
                  reprehenderit? Officia fugiat laboriosam beatae obcaecati
                  quibusdam.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 order-md-2 order-1">
              <div className="careerBenefitImage">
                <img src={Commercial} alt="Fast Growth" />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-center order-md-1 order-2">
              <div className="careerText">
                <h3>
                  <span>Commercial</span>
                </h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Animi, error facilis quas nesciunt, modi accusantium
                  necessitatibus accusamus fugit a culpa laboriosam delectus
                  tenetur aliquam tempore libero quasi illo architecto non.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ResidencialandCommercial;
