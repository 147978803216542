import { React, useLayoutEffect,useState  } from "react";
import { useLocation } from "react-router-dom";
import "../css/PropertyDetails.css";
import GalleryData from "../components/GalleryData";
import { ImLocation } from "react-icons/im";
import PropertyImage2 from "../Img/property2.jpeg";
import Footer from "../components/Footer";
import Form from "./Form";


const PropertyDetails2 = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
    console.log(id);
    const [serviceData] = useState(GalleryData);
    const LData = serviceData.find((e) => e.id == id);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
    return (
      <>
        <div className="propertyDetials">
          {/* <h1>Exclusive Project</h1>
          <h5>Find your dream homes with me.</h5> */}
        </div>
        <section className="propertyDetials1" style={{ backgroundColor: "#eee" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-7">
                <div className="">
                  <div className="imagePoject">
                    <img
                      src="https://thesteadadvisory.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-03-29-at-12.42.35-PM.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="title_section">
                    <h2 className="title_name">{LData.Category}</h2>
                    <p className="loction">
                      <ImLocation />
                      Kokilaben Hospital,Andheri West
                    </p>
                  </div>
  
                  <div class="featureIcon mt-2">
                    <ul>
                      <li>
                        <span>
                          <i aria-hidden="true" class="fa fa-building"></i>
                        </span>
                        <span className="tittlIcon">Property Type:</span>&nbsp;
                        <span class="elementor-icon-list-text">Apartment</span>
                      </li>
                      <li>
                        <span>
                          <i aria-hidden="true" class="fa fa-clone"></i>
                        </span>
                        <span className="tittlIcon">Offer type:</span>&nbsp;
                        <span class="elementor-icon-list-text">For Sale</span>
                      </li>
                    </ul>
                  </div>
                  <hr></hr>
                  <div class="featureIcon">
                    <ul>
                      <div className="row">
                        <div className="col-lg-6">
                          <li>
                            <span>
                              <i aria-hidden="true" class="fa fa-bed"></i>
                            </span>
                            <span>Bedroom:</span>&nbsp;
                            <span class="elementor-icon-list-text">1 & 2</span>
                          </li>
                        </div>
                        <div className="col-lg-6">
                          <li>
                            <span>
                              <i
                                aria-hidden="true"
                                class="fa fa-location-arrow"
                              ></i>
                            </span>
                            <span>Location:</span>&nbsp;
                            <span class="elementor-icon-list-text">
                             {LData.Location}
                            </span>
                          </li>
                        </div>
  
                        <div className="col-lg-6">
                          <li>
                            <span>
                              <i aria-hidden="true" class="fa fa-bath"></i>
                            </span>
                            <span>Bathrooms:</span>&nbsp;
                            <span class="elementor-icon-list-text">2</span>
                          </li>
                        </div>
                        <div className="col-lg-6">
                          <li>
                            <span>
                              <i aria-hidden="true" class="fa fa-car"></i>
                            </span>
                            <span>Parking :</span>&nbsp;
                            <span class="elementor-icon-list-text">
                              1 Alloted With Flat
                            </span>
                          </li>
                        </div>
                        <div className="col-lg-12">
                          <li>
                            <span>
                              <i
                                aria-hidden="true"
                                class="fa fa-pencil-square-o"
                              ></i>
                            </span>
                            <span>Property Size:</span>&nbsp;
                            <span class="elementor-icon-list-text">
                              402 sq.ft(1 BHK)&nbsp;
                            </span>
                            <span class="elementor-icon-list-text">
                              & 640 sq.ft(2 BHK)
                            </span>
                          </li>
                        </div>
                      </div>
                    </ul>
                    <div class="deatailsProject mt-5">
                      <span class="tittle">Address:</span>
                      <span class="price">
                       {LData.Address}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="decsription">
                    <h2>Description:</h2>
                    <p>
                     {LData.Description}
                    </p>
                  </div>
                  <hr />
                  <div className="amenities">
                    <h2>Amenities:</h2>
                    <div className="row">
                      <div className="col-lg-3 col-6">
                        <ul>
                          <li>
                            <span className="material-symbols-outlined">
                              yard
                            </span>
                            <span className="featureTittle">
                              Children play area
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-6">
                        <ul>
                          <li>
                            <span className="material-symbols-outlined">
                              airline_seat_recline_normal
                            </span>
                            <span className="featureTittle">Sitting Zone</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-6">
                        <ul>
                          <li>
                            <span className="material-symbols-outlined">
                              self_improvement
                            </span>
                            <span className="featureTittle">Yoga Zone</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-6">
                        <ul>
                          <li>
                            <span className="material-symbols-outlined">
                              directions_car
                            </span>
                            <span className="featureTittle">Car Parking</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <Form />
              </div>
            </div>
          </div>
        </section>
        <Footer />
  
  </>
  )
}

export default PropertyDetails2

