import { React, useLayoutEffect } from "react";
import "../css/ExclusiveProject.css";
import Footer from "../components/Footer";
import PropertyImage2 from "../Img/Ramdev-veeral-heights/Ramdev_Viral_Day_View_V1 (1) (1).jpg";
import PropertyImage3 from "../Img/property3.jpeg";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
// import { Autoplay, Pagination } from "swiper";
import { Navigation } from "swiper";
import { ImLocation } from "react-icons/im";
import Container from "react-bootstrap/Container";
import maple from "../Img/property3.jpeg";
import property from "../components/property";
import GalleryData from "../components/GalleryData";
const ExclusiveProject = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
     <div className="exclusiveProject">
        <h1>Our Project</h1>
        <h5>Find your dream homes with me.</h5>
      </div>
      <section className="latestProperty lg-mt-5 mt-0">
        <Container>
          <div className="titleSection">
            <h2>Completed Projects</h2>
            <p>
              Browse for the perfect place to live by looking through listings,
              areas, neighborhoods, and insider tips provided by residents.
            </p>
          </div>
          <div className="row row-cols-lg-3 row-cols-sm-3 row-cols-md-2 row-cols-1">
            <Swiper
              navigation={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                570: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay,Navigation]}
              className="mySwiper1"
            >
                {GalleryData.map((elem) => {
              const { id,PropImg, Category,sqft, Location} =
                elem;
                return (
                  <>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img src={PropImg} alt="" />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                      {Location}
                      </p>
                      <h2 className="title_name">{Category}</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            {sqft}
                           
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="button_price">
                    {/* <Link to={`/PropertyDetails2?id=${id}`}>
                      <button className="btn2">Vie</button></Link> */}
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      
                      </a>
                      
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              </>
              );
            })}
                
              {/* <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src={anmol}
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        Malad(E), Mumbai
                      </p>
                      <h2 className="title_name">Anmol Apartment</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                    <button className="btn2">View More</button>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://www.realtorpal.hocud.com/wp-content/uploads/3d-rendering-black-loft-modern-house-in-summer-768x439.jpg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation /> Malad(W), Mumbai
                      </p>
                      <h2 className="title_name">Rishabh Darshan</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                    <button className="btn2">View More</button>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://www.realtorpal.hocud.com/wp-content/uploads/3d-rendering-black-loft-modern-house-in-summer-768x439.jpg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation /> Malad(W), Mumbai
                      </p>
                      <h2 className="title_name">Shree Sai Siddhi</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                    <button className="btn2">View More</button>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>

            {/* <Fade left cascade>
              <div className="col">
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src={maple}
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        Malad(E), Mumbai
                      </p>
                      <h2 className="title_name">Maple Height</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            {" "}
                            590 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="button_price">
                      <a href="/PropertyDetails">View More</a>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <Zoom>
              <div className="col">
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://thesteadadvisory.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-03-29-at-12.42.35-PM.jpeg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        Malad(E), Mumbai
                      </p>
                      <h2 className="title_name">Anmol Apartment</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                      <a href="/PropertyDetails2">View More</a>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
            <Fade right cascade>
              <div className="col">
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://www.realtorpal.hocud.com/wp-content/uploads/3d-rendering-black-loft-modern-house-in-summer-768x439.jpg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation /> Malad(W), Mumbai
                      </p>
                      <h2 className="title_name">Rishabh Darshan</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                      <a href="/PropertyDetails2">View More</a>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Fade> */}
          </div>
        </Container>
      </section>
      <section className="exclusiveProjects">
        <div className="container">
          <div className="row">
          {/* <div className="col-lg-4 col-sm-6">
              <div className="imagePoject">
                <img
                  src={PropertyImage3}
                  alt=""
                />
              </div>
              <div className="deatailsProject">
                <span className="tittle">Maple Heights</span>
                
              </div>
              <p>
                located at Malad(E)  in Calm & Serene location with
                ...
              </p>
              <Link href="" to="/PropertyDetails2">
                <button
                  type="button"
                  className="btn btn-outline-dark mb-sm-3 mb-3"
                >
                  Read More
                </button>
              </Link>
            </div> */}
            {property.map((elem) => {
              const { id,ServiceName,Category } = elem;
              return (
                <>
            <div className="col-lg-4 col-sm-6" key={id}>
           
              <div className="imagePoject">
                <img src={PropertyImage2} alt="" />
              </div>
            
              <div className="deatailsProject">
                <span className="tittle">{ServiceName}</span>
                {/* <span className="price">All Incl. ₹ 82 Lacs Onwards</span> */}
              </div>
              <p>1 & 2 BHK located at Malad(W).</p>
            
              <Link to={`/Propertydetails?id=${id}`}>
                <button
                  type="button"
                  className="btn btn-outline-dark mb-sm-3 mb-3"
                >
                  Read More
                </button>
              </Link>
             
            </div>
            </>
              );
            })}
            {/* <div className="col-lg-4 col-sm-6">
              <div className="imagePoject">
                <img src={PropertyImage3} alt="" />
              </div>
              <div className="deatailsProject">
                <span className="tittle">Luxor Resvese</span>
                <span className="price">All Incl. ₹ 79 Lakh Onwards</span>
              </div>
              <p>1 & 2 BHK located at Boriwali(W)</p>
              <Link href="" to="/PropertyDetails">
                <button
                  type="button"
                  className="btn btn-outline-dark mb-sm-3 mb-3"
                >
                  Read More
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ExclusiveProject;
