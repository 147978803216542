import { React, useLayoutEffect,useState  } from "react";
import "../css/PropertyDetails.css";
import { ImLocation } from "react-icons/im";
import PropertyImage2 from "../Img/property2.jpeg";
import Form from "./Form";
import Footer from "../components/Footer";
import { useLocation,useParams } from "react-router-dom";
import property from "../components/property";

const PropertyDetails = () => {
  // const {name} = useParams();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
    console.log(id);
    const [serviceData] = useState(property);
    const FData = serviceData.find((e) => e.id == id);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="propertyDetials">
        {/* <h1>Exclusive Project</h1>
        <h5>Find your dream homes with me.</h5> */}
      </div>
      <section className="propertyDetials1" style={{ backgroundColor: "#eee" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            {/* {property.filter((valueData) => valueData.Category === name).map((value) => ( */}
            <div className="col-lg-7">
            
              <div className="">
                <div className="imagePoject" style={{ width: "100%" }}>
                  <img
                    src={FData.ServiceImg}  alt=""
                  />
                </div>
                <div className="title_section">
                  <h2 className="title_name">{FData.ServiceName}</h2>
                  <p className="loction">
                    <ImLocation />
                    Nadiadwala Colony-1,Malad West,Mumbai-400 064
                  </p>
                </div>

                <div class="featureIcon mt-2">
                  <ul>
                    <li>
                      <span>
                        <i aria-hidden="true" class="fa fa-building"></i>
                      </span>
                      <span className="tittlIcon">Apartments:</span>&nbsp;
                      <span class="elementor-icon-list-text">49</span>
                    </li>
                    {/* <li>
                      <span>
                        <i aria-hidden="true" class="fa fa-clone"></i>
                      </span>
                      <span className="tittlIcon">Offer type:</span>&nbsp;
                      <span class="elementor-icon-list-text">For Sale</span>
                    </li> */}
                  </ul>
                </div>
                <hr></hr>
                <div class="featureIcon">
                  <ul>
                    <div className="row">
                      <div className="col-lg-6">
                        <li>
                          <span>
                            <i aria-hidden="true" class="fa fa-bed"></i>
                          </span>
                          <span>Storey:</span>&nbsp;
                          <span class="elementor-icon-list-text">18 storey</span>
                        </li>
                      </div>
                      <div className="col-lg-6">
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              class="fa fa-location-arrow"
                            ></i>
                          </span>
                          <span>Location:</span>&nbsp;
                          <span class="elementor-icon-list-text">
                            Malad (W)
                          </span>
                        </li>
                      </div>

                      <div className="col-lg-6">
                        <li>
                          <span>
                            <i aria-hidden="true" class="fa fa-bath"></i>
                          </span>
                          <span>Flats </span>&nbsp;
                          <span class="elementor-icon-list-text">1 & 2 BHK</span>
                        </li>
                      </div>
                      <div className="col-lg-6">
                        <li>
                          <span>
                            <i aria-hidden="true" class="fa fa-car"></i>
                          </span>
                          <span>Parking :</span>&nbsp;
                          <span class="elementor-icon-list-text">
                            1 Alloted With Flat
                          </span>
                        </li>
                      </div>
                      <div className="col-lg-12">
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              class="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span>Sqft Area:</span>&nbsp;
                          {/* <span class="elementor-icon-list-text">
                            405 sq.ft(1 BHK)&nbsp;
                          </span> */}
                          <span class="elementor-icon-list-text">
                            25k sq.ft
                          </span>
                        </li>
                      </div>
                    </div>
                  </ul>
                  <div class="deatailsProject mt-5">
                    <span class="tittle">Address:</span>
                    <span class="price">
                    Nadiadwala Colony-1,Malad West,Mumbai-400 064
                    </span>
                  </div>
                </div>
                <hr />
                {/* <div className="decsription">
                  <h2>Description:</h2>
                  <p>
                    At Passcode Fusion, every apartment is designed to offer the
                    finest and most luxurious living experience. From the minor
                    details to the most standout features, life is worth living
                    at every stage. From the grand entrance lobby to the 20+
                    amenities, it invites you to interact with every square foot
                    mindfully designed to make every experience luxurious like
                    never before.
                  </p>
                </div> */}
                <hr />
                <div className="amenities">
                  <h2>Amenities:</h2>
                  <div className="row">
                    {/* <div className="col-lg-3 col-6">
                      <ul>
                        <li>
                          <span className="material-symbols-outlined">
                            fitness_center
                          </span>
                          <span className="featureTittle">Gymnasium</span>
                        </li>
                      </ul>
                    </div> */}
                    <div className="col-lg-3 col-6">
                      <ul>
                        <li>
                          {/* <span className="material-symbols-outlined">
                            Mandir
                          </span> */}
                          <span className="featureTittle"> Mandir</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-6">
                      <ul>
                        <li>
                          {/* <span className="material-symbols-outlined">
                            Hydraulic Parking
                          </span> */}
                          <span className="featureTittle"> Hydraulic Parking</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-6">
                      <ul>
                        <li>
                          <span className="material-symbols-outlined">
                            
                          </span>
                          <span className="featureTittle">32 car puzzle</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-6">
                      <ul>
                        <li>
                          {/* <span class="material-symbols-outlined">
                            theater_comedy
                          </span> */}
                          <span className="featureTittle">8 car stack</span>
                        </li>
                      </ul>
                    </div>

                  
                  </div>
                </div>
              </div>
              
            </div>
             {/* )
             )} */}
            <div className="col-lg-4">
              <Form />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PropertyDetails;
