import './App.css';
import Navbar from './components/Navbar';
import Home from "./Pages/Home";
import { AboutUs } from './Pages/AboutUs';
import  Career  from './Pages/Career';
import ExclusiveProject from './Pages/ExclusiveProject';
import PropertyDetails from './Pages/PropertyDetails';
import PropertyDetails2 from './Pages/PropertyDetails2';
import ResidencialandCommercial from './Pages/ResidencialandCommercial';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PRE_LEASE_ASSETS from './Pages/PRE_LEASE_ASSETS';
import JointVenture from "./Pages/joinVenture";
import ContactUs from './Pages/contact';
import Vaccancy from './Pages/Vaccancy';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/ExclusiveProject" element={<ExclusiveProject />} />
          <Route path="/PropertyDetails" element={<PropertyDetails />} />
          <Route path="/PropertyDetails2" element={<PropertyDetails2 />} />
          <Route path="/ResidencialandCommercial" element={<ResidencialandCommercial />} />
          <Route path="/PRE_LEASE_ASSETS" element={<PRE_LEASE_ASSETS />} />
          <Route path="/Join_Venture" element={<JointVenture />} />
          <Route path="/conact_us" element={<ContactUs />} />
          <Route path="/Vaccancy" element={<Vaccancy/>} />
        </Routes>
      </BrowserRouter>


    </div>
  );
}

export default App;
