import { React, useLayoutEffect } from "react";
import Footer from "../components/Footer";
import { BsCheckAll } from "react-icons/bs";
import maple from "../Img/banner.jpg";
const JointVenture = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="creerBenefits">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="careerBenefitImage">
                <img
                  src={maple}
                  alt="Training and Devlopment"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-start">
              <div className="careerText">
                <div className="titleSection align-items-start m-0">
                  <h2>Joint Ventures</h2>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci accusamus eius ducimus, atque praesentium eos
                  repellendus explicabo, iusto reiciendis nihil in eum ipsa
                  reprehenderit? Officia fugiat laboriosam beatae obcaecati
                  quibusdam.
                </p>
                <ul>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="row mt-5">
            <div className="col-md-6 order-md-2 order-1">
              <div className="careerBenefitImage">
                <img
                  src="https://thesteadadvisory.com/wp-content/uploads/2022/04/Group-242.png"
                  alt="Fast Growth"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-center order-md-1 order-2">
              <div className="careerText">
                <div className="titleSection align-items-start m-0">
                  <h2>BARTER</h2>
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Animi, error facilis quas nesciunt, modi accusantium
                  necessitatibus accusamus fugit a culpa laboriosam delectus
                  tenetur aliquam tempore libero quasi illo architecto non.
                </p>
                <ul>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default JointVenture;
