import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiFillYoutube } from "react-icons/ai";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import FooterLogo from "../Img/Untitled_design__1_-removebg-preview.png";

const Footer = () => {
  return (
    <>
      {/* <!-- ===================================== Footer Section ====================== --> */}
      <div class="footer-basic">
        <footer className="Footer">
          <div className="row">
            <div className="col-md-4 order-lg-1 order-2">
              <div className="Footer-col-1">
                <div className="Acting-logo-con">
                  <h4>Ramdev Associates</h4>
                </div>
                <div className="Footer-Info">
                  <p>
                  Welcome Ramdev Veeral Heights for those who have truly arrived. Set in Malad (W), a vibrant modern part of the city, this landmark address is for those who are looking to enjoy the spoils of success.</p>
                </div>

                {/* <div className="Footer-Social">
                  <div className="Footer_Social-Media">
                    <span>
                      <a href="" target="_blank">
                        <AiFillInstagram />
                      </a>
                    </span>
                    <span>
                      <a href="" target="_blank">
                        <AiFillFacebook />
                      </a>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-4 order-lg-2 order-3">
              <div className="Footer-Main-Image">
                <div className="FooterImageCon">
                  <div className="FooterLogo">
                    <img src={FooterLogo} alt="" />
                  </div>
                </div>

                <p class="copyright">
                  © Copyright Ramdev Assocaites. All Rights Reserved
                </p>
                <p class="copyright">
                  <a href="https://skdm.in/">
                    Designed by{" "}
                    <strong style={{ color: "var(---TheamGoldColor)" }}>
                      Shree Krishna Digital Marketing
                    </strong>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 order-lg-3 order-1">
              <div className="Footer-col-3">
                <div className="FooterContact">
                  <h4>Contact Us</h4>
                </div>

                <div className="Contact-Details">
                  <div className="Contact-Info">
                    <div className="icon">
                      <FaMapMarkedAlt />
                    </div>
                    <div className="FooterText">
                      <h4>
                      Ramdev Veeral Heights,
                       Nadiadwala Colony  1, Malad West, Mumbai- 400064, Maharashtra,INDIA.
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="Contact-Details">
                  <div className="Contact-Info">
                    <div className="icon">
                      <IoIosCall />
                    </div>
                    <div className="FooterText">
                      <h4>+91- 8652628712/9820449129</h4>
                    </div>
                  </div>
                </div>

                <div className="Contact-Details">
                  <div className="Contact-Info">
                    <div className="icon">
                      <RiSendPlaneFill />
                    </div>
                    <div className="FooterText">
                      <h4>ramdevassociates123@gmail.com</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
