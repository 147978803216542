import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "../css/Navbar.css";
import Logo from "../Img/Untitled_design__1_-removebg-preview.png";
import NavDropdown from "react-bootstrap/NavDropdown";

const Navbar1 = () => {
  const [expanded, setExpanded] = useState(false);
  const navRef = useRef(null);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div>
       <Navbar
        ref={navRef}
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
      >
        <Container>
          <Navbar.Brand href="#home">
            <img src={Logo} alt="Company Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="d-flex justify-content-center"
          >
            <Nav>
              <Link
                href="#home"
                className="navLink"
                to="/"
                onClick={handleLinkClick}
              >
                Home
              </Link>
              <Link
                href="#aboutus"
                className="navLink"
                to="/AboutUs"
                onClick={handleLinkClick}
              >
                About Me
              </Link>
              <Link
                href="#career"
                className="navLink"
                to="/Career"
                onClick={handleLinkClick}
              >
                Career
              </Link>
              <Link
                href="#exclusiveproject"
                className="navLink"
                to="/ExclusiveProject"
                onClick={handleLinkClick}
              >
               Projects
              </Link>
              {/* <NavDropdown
                className="navLink p-0"
                title="Services"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="">
                  <Link
                    href="#ResidencialandCommercial"
                    className="navLink m-0"
                    to="/ResidencialandCommercial"
                  >
                    Redevelopment
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                  <Link
                    href="#PRE_LEASE_ASSETS"
                    className="navLink m-0"
                    to="/PRE_LEASE_ASSETS"
                  >
                    New Project
                  </Link>
                </NavDropdown.Item>
              
              </NavDropdown> */}
              <Link
                href="contectus"
                className="navLink"
                to="/conact_us"
                onClick={handleLinkClick}
              >
                Contact Us
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbar1;
