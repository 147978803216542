import { React } from "react";
import Container from "react-bootstrap/Container";
import buildImage1 from "../Img/Ramdev-veeral-heights/Ramdev_Viral_Night_View_V1 (1) (1).jpg";
import Row from "react-bootstrap/Row";
import plan1 from "../Img/Ramdev-veeral-heights/1 bhk type2 (1).png";
import plan2 from "../Img/Ramdev-veeral-heights/2bhk (1).png";
import { Link } from "react-router-dom";
import anmol from "../Img/anmol.jpg";
import Col from "react-bootstrap/Col";
import "../css/Navbar.css";
import GalleryData from "../components/GalleryData";
import contact from "../Img/OwnerImage.jpeg";
import about from "../Img/Ramdev-veeral-heights/Ramdev_Viral_Night_View_V1 (1) (1).jpg";
import "../css/Home.css";
import { Navigation } from "swiper";

import { ImLocation } from "react-icons/im";
import { BiRupee } from "react-icons/bi";
import risabh from "../Img/risabh.jpeg";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import sai from "../Img/sai-siddhi.jpg";
import maple from "../Img/Ramdev-veeral-heights/Ramdev_Viral_Day_View_V1 (1) (1).jpg";
import MyBackgroundImage from "../Img/22.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Home = () => {
  return (
    <div>
      <section className="home_section">
        <Container>
          <Row>
            <Col md={7}>
              <div className="title_section">
                <Fade left>
                  <h1>
                    I deliver the very best in all facets of realtor.
                    Because you deserve no less.
                  </h1>
                </Fade>
                <Fade top>
                  <p className="mt-5">
                    When you’re ready to consider buying, selling, renting, or
                    refinancing a home, I will help you get it done and save you
                    money.
                  </p>
                </Fade>

                <div className="button_list mt-5">
                  <a href="tel:+918652628712">
                    <button className="btn btn-secondary">Contact Me</button>
                    {/* <a href="">View Listening</a> */}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section class="AboutUs" style={{ overflow: "unset" }}>
        <Container>
          <Row>
            <Col lg={7} md={7} className="order-2 order-md-1">
              <div className="aboutTittle">
                <Zoom left cascade>
                  <h2>Ramdev Associates</h2>
                </Zoom>
                <h3>
                Erratic are those who have very little to accomplish, but a lot to rejoice. Rarer still, is abode that fashions the perfect setting for living that remarkable success story. 
                </h3>
                <p className="mt-3">
                Welcome Ramdev Veeral Heights for those who have truly arrived. Set in Malad (W), a vibrant modern part of the city, this landmark address is for those who are looking to enjoy the spoil of success. Serenity brings a whole new perspective to community living, unhindered of the city & greens and a location that allows seamless connectivity to the city. Unique in shape, Intelligent in design, Ramdev Veeral heights is a home your heart always wish Cocooned in a quiet locale, Ramdev Veeral Heights offers a perfect setting to live a maximized life.
                </p>
              </div>
              {/* <link rel="stylesheet" href="" /> */}

              {/* <Link className="m-3" to="/AboutUs">
                About Me
              </Link> */}
              {/* <span class="material-symbols-outlined">arrow_forward_ios</span> */}
              {/* <div className="about-widget-container">
                <ul className="about-icon-list-items about-inline-items">
                  <li className="about-icon-list-item about-inline-item">
                    <span className="about-icon-list-icon">
                      <i aria-hidden="true" className="fa fa-phone" />
                    </span>
                    <Fade left cascade>
                      <a href="tel:+91 9867153732">
                        <span className="about-icon-list-text">
                          +91- 8652628712/9820449129
                        </span>
                      </a>
                    </Fade>
                  </li>
                  <li className="about-icon-list-item about-inline-item">
                    <span className="about-icon-list-icon">
                      <i aria-hidden="true" className="fa fa-envelope" />
                    </span>

                    <Fade left cascade>
                      <a href="mailto:mitheshrealtors595@gmail.com">
                        <span className="about-icon-list-text">
                          ramdevassociates123@gmail.com
                        </span>
                      </a>
                    </Fade>
                  </li>
                </ul>
              </div> */}
            </Col>
            <Col lg={5} md="5" className="order-1 order-md-2">
              <div className="aboutImage">
                <Zoom cascade>
                  <img src={about} alt="" />
                </Zoom>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* -------------------------------------upcoming---------------------- */}
      <div
        className="ltn__upcoming-project-area pt-115 pb-65"
        style={{ backgroundImage: `url(${MyBackgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2 text-center---">
                <h6 className="" style={{ color: "white" }}>
                  Upcoming Projects
                </h6>
                <h1 className="section-title" style={{ color: "white" }}>
                  Dream Living Space <br />
                  Setting New Standards
                </h1>
              </div>
            </div>
          </div>
          <div
            className="row ltn__upcoming-project-slider-1-active slick-arrow-3 slick-initialized slick-slider"
            role="toolbar"
          >
            {/* <a className="slick-prev slick-arrow" style={{}}>
              <i className="fas fa-arrow-left" alt="Arrow Icon" />
            </a> */}
            {/* <div aria-live="polite" className="slick-list draggable"> */}
              <div
                className="col-lg-12 slick-slide slick-cloned"
                style={{ width: 960 }}
                tabIndex={-1}
                role="option"
                aria-describedby="slick-slide42"
                data-slick-index={-1}
                id=""
                aria-hidden="true"
              >
                <div className="ltn__upcoming-project-item">
                  <div className="row">
                    <div className="col-lg-7 col-md-6 col-12">
                      <Swiper
                        navigation={true}
                        loop={true}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper1"
                      >
                         
                        <SwiperSlide>
                          <div className="ltn__upcoming-project-img">
                            <img src={plan1} alt="#" />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="ltn__upcoming-project-img">
                            <img src={plan2} alt="#" />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="ltn__upcoming-project-img">
                            <img src={buildImage1} alt="#" />
                          </div>
                        </SwiperSlide>
                      
           
                      </Swiper>
                    </div>

                    <div className="col-lg-5 col-md-6 col-12 section-bg-1">
                      <div className="ltn__upcoming-project-info ltn__menu-widget">
                        <h6 className="ltn__secondary-color">Ongoing Projects</h6>
                        <h1>Ramdev Veeral Heights</h1>
                        <ul>
                          <li>
                            1. Project Name: <span>Ramdev Veeral Heights</span>
                          </li>
                          <li>
                            2. Project Type: <span>Apartment</span>
                          </li>
                          <li>
                            3. Building Location: <span>Malad (W)</span>
                          </li>
                          <li>
                            4. No. Of Apartments: <span>49</span>
                          </li>
                          <li>
                            5. Square Feet: <span>25000 sq. ft.</span>
                          </li>
                        </ul>
                        {/* <div className="btn-wrapper animated go-top">
                                <a
                                  className="theme-btn-1 btn btn-effect-1"
                                  href="#/contact"
                                  tabIndex={-1}
                                >
                                  Download Brochure
                                </a>
                              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <a className="slick-next slick-arrow" style={{}}>
              <i className="fas fa-arrow-right" alt="Arrow Icon" />
            </a> */}
          </div>
        </div>
      </div>
      <section className="latestProperty2 lg-mt-5 mt-0"></section>

      <section className="latestProperty lg-mt-5 mt-0">
        <Container>
          <div className="titleSection">
            <h2>Completed Projects</h2>
            <p>
              Browse for the perfect place to live by looking through listings,
              areas, neighborhoods, and insider tips provided by residents.
            </p>
          </div>
          <div className="row row-cols-lg-3 row-cols-sm-3 row-cols-md-2 row-cols-1">
            <Swiper
              navigation={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                570: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay,Navigation]}
              className="mySwiper1"
            >
                {GalleryData.map((elem) => {
              const { id,PropImg, Category,sqft,Location} =
                elem;
                return (
                  <>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img src={PropImg} alt="" />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        {Location}
                      </p>
                      <h2 className="title_name">{Category}</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            {" "}
                           {sqft}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="button_price">
                      
                    {/* <Link to={`/PropertyDetails2?id=${id}`}>
                      <button className="btn2">View More</button></Link> */}
                      <a href="tel:+8652628712">
                        <button className="btn btn-info">Contact Us</button>
                      
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              </>
              );
            })}
                
              {/* <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src={anmol}
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        Malad(E), Mumbai
                      </p>
                      <h2 className="title_name">Anmol Apartment</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                    <button className="btn2">View More</button>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://www.realtorpal.hocud.com/wp-content/uploads/3d-rendering-black-loft-modern-house-in-summer-768x439.jpg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation /> Malad(W), Mumbai
                      </p>
                      <h2 className="title_name">Rishabh Darshan</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                    <button className="btn2">View More</button>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://www.realtorpal.hocud.com/wp-content/uploads/3d-rendering-black-loft-modern-house-in-summer-768x439.jpg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation /> Malad(W), Mumbai
                      </p>
                      <h2 className="title_name">Shree Sai Siddhi</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                    <button className="btn2">View More</button>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>

            {/* <Fade left cascade>
              <div className="col">
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src={maple}
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        Malad(E), Mumbai
                      </p>
                      <h2 className="title_name">Maple Height</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            {" "}
                            590 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="button_price">
                      <a href="/PropertyDetails">View More</a>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <Zoom>
              <div className="col">
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://thesteadadvisory.com/wp-content/uploads/2022/01/WhatsApp-Image-2022-03-29-at-12.42.35-PM.jpeg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation />
                        Malad(E), Mumbai
                      </p>
                      <h2 className="title_name">Anmol Apartment</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                      <a href="/PropertyDetails2">View More</a>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
            <Fade right cascade>
              <div className="col">
                <div className="card cardList">
                  <div className="imageFeature">
                    <img
                      src="https://www.realtorpal.hocud.com/wp-content/uploads/3d-rendering-black-loft-modern-house-in-summer-768x439.jpg"
                      alt=""
                    />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                      <p className="loction">
                        <ImLocation /> Malad(W), Mumbai
                      </p>
                      <h2 className="title_name">Rishabh Darshan</h2>
                    </div>
                    <div className="featureIcon">
                      <ul>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bed"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-bath"></i>
                          </span>
                          <span className="elementor-icon-list-text">2</span>
                        </li>
                        <li>
                          <span>
                            <i aria-hidden="true" className="fa fa-car"></i>
                          </span>
                          <span className="elementor-icon-list-text">1</span>
                        </li>
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fa fa-pencil-square-o"
                            ></i>
                          </span>
                          <span className="elementor-icon-list-text">
                            640 sq.ft(2 BHK)
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="button_price">
                      <a href="/PropertyDetails2">View More</a>
                      <a href="tel:+91 9867153732">
                        <button className="btn btn-info">Contact Us</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Fade> */}
          </div>
        </Container>
      </section>

      {/* <section className="latestProperty lg-mt-5 mt-0">
        <Container>
          <div className="titleSection">
            <h2>Redevelopment Projects</h2>
            <p>
              Browse for the perfect place to live by looking through listings,
              areas, neighborhoods, and insider tips provided by residents.
            </p>
          </div>
          <div className="row row-cols-lg-3 row-cols-sm-3 row-cols-md-2 row-cols-1">
            <Swiper
              navigation={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                570: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay,Navigation]}
              className="mySwiper1"
            >
              {GalleryData.map((elem) => {
              const { id, PropImg,Category} =
                elem;
                return (
                  <>
              <SwiperSlide>
                <div className="card cardList">
                  <div className="imageFeature">
                    <img src={PropImg} alt="" />
                  </div>

                  <div className="card_body">
                    <div className="title_section">
                     
                      <h2 className="title_name">{Category}</h2>
                    </div>
                   
                  </div>
                </div>
              </SwiperSlide>
          
                </>
              );
            })}
            </Swiper>
          </div>
        </Container>
      </section> */}

      {/* -------------------------why choose us--------------- */}

      <div className="ltn__about-us-area section-bg-1 bg-image-right-before pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    Building Facilities
                  </h6>
                  <h1 className="section-title">
                    Making living spaces More Beautiful
                  </h1>
                  <p>
                  Developed residential area with excellent civic amenities and ample greenery around
                  </p>
                </div>
                <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                  <li>
                    <i className="fa fa-check" />
                    Luxurious interior design and amenities
                  </li>
                  <li>
                    <i className="fa fa-check" />
                  24 x7 survelliance camera in Entrance lobby
                  </li>
                  <li>
                    <i className="fa fa-check" />
                    Rain Water Harvesting System
                  </li>
                  <li>
                    <i className="fa fa-check" />
                    Puzzle world class hydraulic system with stack car parking
                  </li>
                  <li>
                    <i className="fa fa-check" />Advanced Fire Fighting System
                  </li>
                  <li>
                    <i className="fa fa-check" />
                    Double Height Entrance lobby superior finishes
                  </li>
                </ul>
                <div className="  ltn__animation-pulse2 text-center mt-30">
                  <a
                    className="ltn__video-play-btn bg-white--- ltn__secondary-bg"
                    href="https://www.youtube.com/embed/HnbMYzdjuBs?autoplay=1&showinfo=0"
                    data-rel="lightcase"
                  >
                    <i className="icon-play  ltn__secondary-color--- white-color" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left" />
            </div>
          </div>
        </div>
      </div>
    

     
    

      <section className="testimonialSection" id="reviews">
        <div className="container">
          <div className="titleSection">
            <h2>Testimonials</h2>
            {/* <p>
                I assist my customers in getting to the heart of their real
                estate demands, desires, and outcomes. I'm in this for the long
                term.
              </p> */}
          </div>
          <div className="row">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="testimonialList">
                  <div className="testimonialCard">
                    <i
                      className="fa fa-quote-left qouateIcon"
                      aria-hidden="true"
                    />
                    <div className="testimonialText">
                      <p>
                      This is a very good location near mind space near orbit and infinity mall mind space is a commercial hub neat and clean society.
                      </p>
                    </div>
                    <ul>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                    </ul>
                    <div className="userDetail">
                      {/* <div className="userImg">
                        <img
                          src="https://highwayconcessions.com/wp-content/uploads/2018/10/profile-picture-demo-9.jpg"
                          alt=""
                        />
                      </div> */}
                      <div className="userName">
                        <h3>Anil Mishra</h3>
                        {/* <span>Gujrat</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  /> */}
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonialList">
                  <div className="testimonialCard">
                    <i
                      className="fa fa-quote-left qouateIcon"
                      aria-hidden="true"
                    />
                    <div className="testimonialText">
                      <p>
                      Totally well-connected and developed area. It's near theatres, hospitals, schools, hotels and moreover a metro station.
                      </p>
                    </div>
                    <ul>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                    </ul>
                    <div className="userDetail">
                      {/* <div className="userImg">
                        <img
                          src="https://highwayconcessions.com/wp-content/uploads/2018/10/profile-picture-demo-9.jpg"
                          alt=""
                        />
                      </div> */}
                      <div className="userName">
                        <h3>Bharat</h3>
                        {/* <span>Gujrat</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  /> */}
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonialList">
                  <div className="testimonialCard">
                    <i
                      className="fa fa-quote-left qouateIcon"
                      aria-hidden="true"
                    />
                    <div className="testimonialText">
                      <p>
                      All amenities in the locality like a Swimming pool Health club, gymnasium, Carrom, Chess, Pool, Party Hall, Garden Joggers park etc.
                      </p>
                    </div>
                    <ul>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                      <li>
                        <i className="fa fa-star" aria-hidden="true" />
                      </li>
                    </ul>
                    <div className="userDetail">
                      {/* <div className="userImg">
                        <img
                          src="https://highwayconcessions.com/wp-content/uploads/2018/10/profile-picture-demo-9.jpg"
                          alt=""
                        />
                      </div> */}
                      <div className="userName">
                        <h3>Kalpesh</h3>
                        {/* <span>Gujrat</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  /> */}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

  {/* --------------------------------count figures------------------------ */}
  <section className="p-0" style={{ display: "unset" }}>
        <Row>
          <Col lg={3} md={6} sm={6} className="p-0 m-0">
            <div className="activityCard">
              <div className="activityIcon">
                <Zoom>
                  <span className="material-symbols-outlined">
                    {" "}
                    rocket_launch{" "}
                  </span>
                </Zoom>
              </div>

              <div className="activityinfo">
                {" "}
                <Zoom cascade>25+ Years of Experience</Zoom>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} className="p-0 m-0">
            <div className="activityCard">
              <div className="activityIcon">
                <Zoom>
                  <span className="material-symbols-outlined"> groups </span>
                </Zoom>
              </div>

              <div className="activityinfo">
                <Zoom cascade> 1500+ Satisfied Clients </Zoom>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} className="p-0 m-0">
            <div className="activityCard">
              <div className="activityIcon">
                <Zoom>
                  <span className="material-symbols-outlined"> map </span>
                </Zoom>
              </div>

              <div className="activityinfo">
                {" "}
                <Zoom cascade> 25 Location Covered</Zoom>{" "}
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} className="p-0 m-0">
            <div className="activityCard">
              <div className="activityIcon">
                <Zoom>
                  <span className="material-symbols-outlined"> thumb_up </span>
                </Zoom>
              </div>

              <div className="activityinfo">
                {" "}
                <Zoom cascade> 100+ Five Star Ratings </Zoom>
              </div>
            </div>
          </Col>
        </Row>
      </section>
 {/* -------------------------------services provide------------------------------ */}
 <section className="Services" style={{ overflow: "unset" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center flex-column align-items-center">
              <h2>Services I Provide</h2>
              <h3>
                Appreciated for consistently outperforming client expectations
                and providing exceptional results.
              </h3>
            </div>
          </div>
          <div className="servicePosition">
            <div className="row row-cols-lg-3 row-cols-sm-3 row-cols-1">
              <div className="col-lg-4 col-md-6 col-12 p-0 px-sm-1 mb-2">
                <div className="serviceCard shadow">
                  <Zoom cascade>
                    <div className="servicIcon">
                      <span class="material-symbols-outlined">
                        real_estate_agent
                      </span>
                    </div>
                  </Zoom>
                  <div className="serviceTitle">
                    <Fade left cascade>
                      <h2>Redevelopment</h2>
                    </Fade>
                  </div>
                  <div className="serviceText">
                    <p>
                    At Ramdev Associates, we are excited to present our transformative vision for building redevelopment, where modern living meets unparalleled amenities. 
                    </p>
                  </div>
                  {/* <Link className="m-3" to="/ResidencialandCommercial">
                    Learn More
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 p-0 mb-2">
                <div className="serviceCard shadow">
                  <Zoom cascade>
                    <div className="servicIcon">
                      <span class="material-symbols-outlined">
                        location_automation
                      </span>
                    </div>
                  </Zoom>

                  <div className="serviceTitle">
                    <Fade left cascade>
                      <h2>New poject</h2>
                    </Fade>
                  </div>
                  <div className="serviceText">
                    <p>
                    Ramdev Associates offers a collection of meticulously crafted residences that exude elegance, style, and comfort.
                    </p>
                  </div>
                  {/* <Link className="m-3" to="/PRE_LEASE_ASSETS">
                    Learn More
                  </Link> */}
                </div>
              </div>
           
              <div className="col-lg-4 col-md-6 col-12 p-0 px-sm-1 mb-2">
                <div className="serviceCard shadow">
                  <Zoom>
                    <div className="servicIcon">
                      <span class="material-symbols-outlined">work</span>
                    </div>
                  </Zoom>
                  <div className="serviceTitle">
                    <Fade left cascade>
                      <h2>Commercial Project</h2>
                    </Fade>
                  </div>
                  <div className="serviceText">
                    <p>
                    Our commercial projects boast cutting-edge conference rooms and meeting spaces equipped with the latest audiovisual technology. 
                    </p>
                  </div>
                  {/* <Link className="m-3" to="/Join_Venture">
                    Learn More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------------------contact-------------------------- */}
      <section className="contactUs">
        <div className="container">
          <div className="titleSection">
            <h2>Contact Us</h2>
            <p>
              If you have any questions about the real estate market, I'd love
              to chat. Reach out below, and I'll get back to you shortly. I look
              forward to hearing from you.
            </p>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
              <div className="contactImg ">
                <Zoom>
                  <img src={contact} alt="" />
                </Zoom>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-center">
              <div
                className="contactPersonDetail aos-init aos-animate"
                data-aos="fade-left"
              >
                <Fade right>
                  <h2>Ramdev Associates</h2>
                  <span>Builders & Developers</span>
                </Fade>
                <ul>
                  <li>
                    <a href="tel:+91 9867153732">
                      <span className="material-symbols-outlined">call</span>
                      <span>+91- 8652628712/9820449129</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:mithesrealtors595@gmail.com">
                      <span className="material-symbols-outlined">mail</span>
                      <span>ramdevassociates123@gmail.com</span>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <span className="material-symbols-outlined">
                        pin_drop
                      </span>
                      <span>
                      Ramdev Veeral Heights, Nadiadwala Colony 1, Malad West, Mumbai- 400064, Maharashtra,INDIA.
                      </span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="">
                      <span className="material-symbols-outlined">
                        schedule
                      </span>
                      <span>All days of week</span>
                    </a>
                  </li>

                  {/* <li className="socialMediaLink">
                    <a href="">
                      <i className="fa fa-facebook" aria-hidden="true" />{" "}
                    </a>
                    <a href="">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                    <a href="">
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                    <a href="">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                  </li> */}
                  {/* <li><span style="color:red; font-size:1.2rem; font-weight:500;">(Please Co-ordinate with mail only)</span></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
