
import React, { useState } from "react";
import Footer from "../components/Footer";
import { BsCheckAll } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import Service from "../components/Service";

const Vaccancy = () => {
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    console.log(id);
    const [serviceData] = useState(Service);
    const FData = serviceData.find((e) => e.id == id);
  return (
    <div>
 <section className="creerBenefits">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="careerBenefitImage">
                <img
                  src={FData.ServiceImg}
                  alt="Training and Devlopment"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-start align-items-start">
              <div className="careerText">
                <div className="titleSection align-items-start m-0">
                  <h2>{FData.ServiceName}</h2>
                </div>
                <p>
                {FData.ServicePara1}
                </p>
                <ul>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                  <li>
                    <span>
                      <BsCheckAll />
                    </span>
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Vaccancy