import maple from "../Img/aboutus2.jpeg";
import sai from "../Img/sai-siddhi.jpg";
import ramdev from "../Img/Ramdev-veeral-heights/Ramdev_Viral_Day_View_V1 (1) (1).jpg";
import anmol from "../Img/anmol.jpg";
import guru from "../Img/guru.jpg";
import arihant from "../Img/arihant.jpg";
import chint from "../Img/chint.jpg";
import ris from "../Img/risabh.jpeg";
import mangal from "../Img/mangal.jpg";
import shilpa from "../Img/shilpa.jpg";
import bal from "../Img/balkrish.jpg";
const GalleryData = [

    
    {
        id:1,
        PropImg:maple,
        Category:"Maple Heights",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"12000 sq.ft.",
        Location:"Malad(w)"
   
    },

    {
        id:2,
        PropImg:bal,
        Category:"Balkrishna Nagar",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"74000 sq.ft.",
        Location:"Bhayander(E)"
    },

   

    {
        id:3,
        PropImg:shilpa,
        Category:"Shilpa Apartment",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"48000 sq.ft.",
        Location:"Bhayander(E)"
    },

   
    {
        id:4,
        PropImg:sai,
        Category:"Bahubali Apartment",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"40000 sq.ft.",
        Location:"Bhayander(E)"
    },
   
   
    {
        id:5,
        PropImg:mangal,
        Category:"MangalBhavan",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"36000 sq.ft.",
        Location:"Ghatkopar(W)"
    },
    {
        id:6,
        PropImg:sai,
        Category:"Chandralok Apartment",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"36000 sq.ft.",
        Location:"Bhayander(E)"
    },
    {
        id:8,
        PropImg:ris,
        Category:"Rishabh Darshan",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"21000 sq.ft.",
        Location:"Malad(W)"
    },
    {
        id:7,
        PropImg:guru,
        Category:"Gururkripa Niwas",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"23000 sq.ft.",
        Location:"Malad(W)"
    },
    {
        id:9,
        PropImg:anmol,
        Category:"Anmol Apartment",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"20000 sq.ft.",
        Location:"Malad(E)"
    },
    {
        id:10,
        PropImg:chint,
        Category:"Chintamani Apartment",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"18000 sq.ft.",
        Location:"Vile Parle(E)"
    },
    {
        id:11,
        PropImg:sai,
        Category:"Shree Sai siddhi",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"5000 sq.ft.",
        Location:"Malad(W)"
    },
    {
        id:11,
        PropImg:arihant,
        Category:"Arihant Residency",
        Address:"Close To Kokilaben Hospital, Infinity Mall, Lokhandwala Market, D.N Nagar Metro Station, Sai Baba Temple,Andheri(West).",
        Description:"Jay Celestial is 13 storey tower located in serene location of Andheri West near to New Link Road with excellent connectivity via Road,Railway & Metro with well-designed flats with some of the finest amenities",
        sqft:"14000 sq.ft.",
        Location:"Malad(E)"
    }
   
]

export default GalleryData;