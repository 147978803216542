import { React, useLayoutEffect } from "react";
import "../css/AboutUS.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ownerImage from "../Img/aboutus3.png";
import Footer from "../components/Footer";

export const AboutUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
    <div className="aboutBanner">
      <h1>About Us</h1>
      {/* <h5>
        Vision and determination have been a hallmark of prince Currier
        Service itself.
      </h5> */}
    </div>
    <section class="AboutUs" style={{ overflow: "unset" }}>
      <Container>
        <Row>
          <Col lg={7} className="mt-5 order-2 order-md-1">
            <div className="aboutTittle">
              <h2>Ramdev Associates</h2>
              <h3>The most acceptable realtor you can trust.</h3>
              <p className="mt-3">
              Ramdev Associates, a renowned leader in construction and development, is thrilled to introduce an exciting redevelopment project for Ramdev Veeral Heights. We are committed to transforming this iconic building into a modern, functional, and aesthetically appealing space that meets the evolving needs of the community. With our expertise and dedication to excellence, we aim to create a landmark that will inspire and enrich the lives of all who experience it.
              </p>
              <p className="mt-3">
              Our redevelopment services offer a comprehensive solution to breathe new life into buildings while maximizing their potential.     </p>
            </div>

            <div className="about-widget-container">
              <ul className="about-icon-list-items about-inline-items">
                <li className="about-icon-list-item about-inline-item">
                  <span className="about-icon-list-icon">
                    <i aria-hidden="true" className="fa fa-phone" />
                  </span>
                  <span className="about-icon-list-text">+91- 8652628712/9820449129</span>
                </li>
                <li className="about-icon-list-item about-inline-item">
                  <span className="about-icon-list-icon">
                    <i aria-hidden="true" className="fa fa-envelope" />
                  </span>
                  <span className="about-icon-list-text">
                  ramdevassociates123@gmail.com
                  </span>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={5} className="order-1 order-md-2">
            <div className="aboutImage m-0" style={{ height: "466px" }}>
              <img src={ownerImage} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <Footer />
  </div>
  );
};
